.header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  border-bottom: 1px solid #EEEEEE;

  &:after, &:before {
    position: absolute;
    content: "";
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EEEEEE;
  }

  &:before {
    right: 100%;
    width: calc(100vw - 100%);
  }

  &:after {
    left: 100%;
    width: calc(100vw - 100%);
  }
}

.avatar {
  width: 40px;
  height: 40px;
}

.column {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  & > p {
    & + p {
      margin-top: 0.25rem;
    }
  }
}

.info {
  display: flex;
  align-items: center;
}
