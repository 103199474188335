.header {
  width: 100%;
  display: flex;
  align-items: center;

  & > div {
    & + div {
      margin-left: 2rem;
    }

    &:last-child {
      max-width: 10rem;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    & > div {
      width: 100%;

      & + div {
        margin-left: 0;
        margin-top: 2rem;
      }

      &:last-child {
        max-width: 100%;
      }
    }
  }
}

.add_note {
  margin-top: 2rem;
}

.wrap {
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.root {
  width: 100%;
  margin-top: 2rem;
  background: #FFFFFF;
  box-shadow: 0 0 0 1px #E9E9E9 inset;
  border-radius: 8px 8px 0 0;
  border-collapse: collapse;
  overflow: hidden;
}

.controls {
  min-height: 4.25rem;
  border-bottom: 0 solid #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.8rem 1.5rem;

  &_buttons {
    margin-left: auto;
    display: flex;
    align-items: center;

    & > * {
      padding: 0.5rem;

      & + * {
        margin-left: 1rem;
      }
    }
  }
}

.cell {
  cursor: pointer;
  min-height: 9.7rem;
  padding: 0.5rem;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0 0 0 1px #E9E9E9 inset;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  &_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: auto;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &_filled {
    background-image: url('../../assets/images/lines45.svg');
    background-position: center;
    background-size: cover;
    pointer-events: none;
  }

  &_number {
    min-width: 2.5rem;
    min-height: 2.5rem;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    &_today {
      background-color: var(--color-primary);
      border-radius: 50%;
    }
  }

  &_tag {
    margin-top: 0.9rem;
  }

  &_weekday {
    margin-left: auto;

    @media (max-width: 767px) {
      margin-right: auto;
    }
  }

  &_countButton {
    background-color: var(--color-link);
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 0.2rem;
  }
}

.new_entry {
  & > div {
    & + div {
      margin-top: 1.5rem;
    }
  }

  & > button {
    margin-top: 2.5rem;
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  &_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &_wrap {
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
  }

  &_item {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: -1rem;
      left: -1rem;
      right: -1rem;
      bottom: -1rem;
      background: #FBFBFB;
      border: 1px solid #E9E9E9;
      border-radius: 6px;
      z-index: -1;
      opacity: 0;
      transition: opacity .1s ease-in-out;

      @media (max-width: 767px) {
        opacity: 1;
      }
    }

    & > button {
      margin-left: auto;
      opacity: 0;
      transition: opacity .1s ease-in-out;
      pointer-events: none;

      @media (max-width: 767px) {
        opacity: 1;
        pointer-events: all;
      }
    }

    &_name {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & + & {
      margin-top: 1.5rem;

      @media (max-width: 767px) {
        margin-top: 2.5rem;
      }
    }

    &:hover {
      &:after {
        opacity: 1;
      }

      & > button {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.noEntries {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    margin-top: 1rem;
  }
}