.container {
  max-width: calc(1120px + 2rem);
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
