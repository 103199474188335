.root {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.label {
  display: block;
  margin-right: 0.5rem;
}

.input {
  width: 1.5rem;
  height: 1.5rem;
}