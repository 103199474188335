.root {
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;

  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.name {
  margin-bottom: 1rem;
}

.phone {
  margin-bottom: 0.3rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 2rem;

  @media (max-width: 400px) {
    margin-left: 0;
    margin-top: 2rem;
  }
}