.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  max-width: 40rem;
}

.backward {
  margin-bottom: 1rem;
}

.form {
  margin-top: 2rem;
  max-width: 34rem;

  & > div {
    & + div {
      margin-top: 2rem;
    }
  }
}

.submit {
  margin-top: 3rem;
}

.doubled {
  display: flex;
  align-items: flex-start;

  & > div {
    flex: 1;

    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }
}

.withControls {
  display: flex;
  align-items: center;
}

.delete {
  max-width: 38rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &_button {
    margin-top: 2.2rem;
    margin-bottom: 1rem;
  }
}

.sidebar {
  max-width: 34rem;
  position: sticky;
  top: 2rem;
}