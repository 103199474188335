.wrap {
  & > button {
    margin-top: 2rem;
  }
}

.root {
  display: flex;
  align-items: flex-start;
  position: relative;

  & + .root {
    margin-top: 2rem;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.select {
  width: 100%;
  max-width: 13rem;

  @media (max-width: 450px) {
    max-width: 100%;
  }
}

.input {
  display: flex;
  align-items: center;
  max-width: 14rem;
  min-width: 14rem;
  position: relative;
  margin-left: 0.8rem;

  & > * {
    & + * {
      margin-left: 0.8rem;
    }
  }

  &_control {
    position: absolute;
    left: 100%;
    top: 0.7rem;
  }

  @media (max-width: 450px) {
    margin-left: 0;
    max-width: 17rem;
  }
}

.schedules {
  min-height: 3rem;
  max-width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .input + .input {
    margin-top: 1rem;
  }

  @media (max-width: 450px) {
    margin-top: 0.8rem;
    max-width: 17rem;
  }
}

.controls {
  position: absolute;
  left: calc(100% + 2.5rem);
  top: 0.7rem;
}
