.root {
  position: relative;
  overflow: hidden;
}

.blocker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: var(--layer-blocker);
  background-color: rgba(255, 255, 255, 0.5);
  cursor: progress;
  display: flex;
  justify-content: center;
  align-items: center;
}
