.form {
  & > div {
    & + div {
      margin-top: 1.5rem;
    }
  }

  & > button {
    margin-top: 2.5rem;
  }
}

