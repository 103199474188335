.wrap {
  display: flex;
  flex-direction: column;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(0, 1fr);

  &_subheading {
    margin-bottom: 0.5rem;
  }

  &_header {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &_item {
    width: 100%;

    @media (max-width: 400px) {
      grid-column: span 2;
      margin-bottom: 0;

      & + & {
        margin-top: 2rem;
      }
    }
  }

  &_map {
    grid-column: span 2;
  }
}

.map {
  margin-top: 3rem;
  min-height: 25rem;
  border: 1px solid #EEEEEE;
  border-radius: 0.5rem;
  overflow: hidden;

  & > div {
    min-height: 25rem;
  }
}

.edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &_fields {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 34rem;
    width: 100%;

    & > div {
      & + div {
        margin-top: 3rem;
      }
    }
  }

  &_address {
    & > div {
      & + div {
        margin-top: 2rem;
      }
    }
  }

  &_submit {
    margin-top: 3rem;
  }

  &_map {
    width: 100%;
  }
}

.checkbox {
  margin-top: 1rem;
  margin-bottom: 3rem;
}