.wrap {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  background-color: #ffcf4e;
  position: relative;

  & > div {
    position: relative;

    & > input {
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 1rem;
      color: #212121;
      width: 100%;
      padding: 1.21rem 1rem 0.3rem;
      background-color: #fbfbfb;
      border-radius: 4px;
      border: none;
      outline: none;
      padding-right: 3rem;
      position: relative;

      &:focus,
      &:valid {
        & + span {
          font-size: 0.65rem;
          top: 0.2rem;
        }
      }
    }

    & > span {
      pointer-events: none;
      position: absolute;
      color: #9e9e9e;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 1rem;
      top: 0.8rem;
      left: 1rem;
      right: 3rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition: all 0.1s ease-in-out;
    }

    & > svg {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 100vw;
    height: 100%;
    top: 0;
    left: calc(((100vw - 100%) / -2));
    background-color: #ffcf4e;
    z-index: -1;
  }
}

.disabled {
  pointer-events: none;
}
