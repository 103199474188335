.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
}

.header {
    z-index: 1;
}

.footer {
    margin-top: auto;
}
