.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.063rem solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem 2rem 2rem;
}

.name {
  margin-bottom: 0.6rem;
}

.info {
  * + * {
    margin-top: 0.4rem;
  }

  margin-bottom: 1.5rem;
}