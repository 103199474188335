.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.groups {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 0.7rem;

    & + * {
      margin-left: 0.7rem;
    }
  }
}

.schedules {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &_item {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;

    & > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: -1rem;
    }

    & > p {
      margin-bottom: 0.5rem;
    }
  }

  &_times {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-bottom: 0.5rem;

    & > * + * {
      margin-left: 0.8rem;
    }
  }
}
