.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--layer-modal);
  overflow: auto;
}

.wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border-radius: 16px;
  min-width: 38rem;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: auto;
  padding: 24px 32px 32px;

  @media (max-width: 500px) {
    min-width: calc(320px - 2rem);
    width: calc(100% - 2rem);
  }
}

.content {
  overflow-x: auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  margin-right: auto;
}

.close {
  margin-left: auto;
  cursor: pointer;
}
