$border-focus: #ffcc41;

.wrap {
  position: relative;
  width: 100%;
  max-width: 34rem;

  &_fullWidth {
    max-width: none;
  }
}

.select {
  max-width: 34rem;
  width: 100%;
  padding: 0.438rem 4rem 0.438rem 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 2rem;
  letter-spacing: 0.2px;
  color: #212121;
  background: #fbfbfb;
  border: 2px solid #e9e9e9;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  outline: none;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;

  &[disabled] {
    cursor: not-allowed;;
  }

  & > option {
    width:100px;
    text-overflow:ellipsis;
    overflow:hidden;
  }

  &:focus {
    border-color: $border-focus;

    & + div {
      transform: translateY(-50%) rotateZ(180deg);
    }
  }

  &_fullWidth {
    max-width: none;
  }

  &_error {
    border-color: var(--color-error);

    &:focus {
      border-color: var(--color-error);
    }
  }
}

.caretIcon {
  pointer-events: none;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.34rem;
  height: 0.8rem;
  transition: transform 0.1s ease-in-out;

  & > span {
    display: block;
    width: 0.8rem;
    height: 0.1rem;
    border-radius: 0.2rem;
    background-color: #9e9e9e;
    position: absolute;
    top: 50%;
    transition: all 0.1s ease-in-out;

    &:first-child {
      left: 0.2px;
      transform: rotateZ(45deg);
    }

    &:last-child {
      right: 0.2px;
      transform: rotateZ(-45deg);
    }
  }
}
