.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.image {
  width: 8rem;
  height: 8rem;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.delete {
  cursor: default;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: var(--color-error);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  path {
    fill: #FFFFFF;
  }
}