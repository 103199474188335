$active: #ffcc41;

$link-hover: #faf7e0;

.button {
  border-radius: 4.625rem;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  border: 0.063rem solid #212121;
  background-color: white;

  & > span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  &_active {
    outline: none;
    background-color: $active;
    border-color: transparent;

    & > span {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        left: -1px;
        right: -1px;
        top: -1px;
        bottom: -1px;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

.uppercase {
  & > span {
    text-transform: uppercase;
  }
}

.primary {
  &:hover {
    border: 0.063rem solid #212121;
    background-color: white;
  }

  &:active {
    background-color: $active;
  }
}

.outline {
  background-color: transparent;

  &:active {
    border: 1px solid $active;
  }
}

.link {
  background-color: transparent;

  & > span {
    padding: 0;
    height: auto;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: normal;
  }
}

.ghost {
  background-color: transparent;
}
