.root {
  position: relative;
  min-height: 3em;
}

.overlay {
  position: absolute;
  z-index: var(--layer-blocker);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: white;
  }
}