.wrap {
  display: flex;
  align-items: center;
}

.item {
  flex: 1;
  position: relative;

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: 1rem;
  }
}

.label {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.withPadding {
  padding-left: 6.3rem;
}
