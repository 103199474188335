.root {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.control {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & + .control {
    margin-left: 1.5rem;
  }

  path {
    transition: fill .1s ease-in-out;
  }

  &:hover {
    path {
      fill: var(--color-link);
    }
  }
}
