.row {
  display: flex;
  padding-top: 3.5rem;
  padding-bottom: 2.5rem;

  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.avatar {
  position: relative;
  max-width: 10rem;
  max-height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.wrap {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  @media (max-width: 400px) {
    margin-left: 0;
    margin-top: 2rem;
  }
}

.mail {
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  color: #0068ff;
}

.phone {
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  color: black;
  margin-top: 1rem;
}

.title {
  line-height: 1rem;
}

.title1 {
  margin-top: 0.5rem;
  line-height: 2.5rem;
}

.camera {
  position: absolute;
  bottom: 0;
  right: 0;
}