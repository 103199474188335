.header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.form {
  max-width: 34rem;

  & > * {
    & + * {
      margin-top: 1.5rem;
    }
  }
}

.avatar {
  display: flex;

  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.content {
  margin-left: 1.5rem;

  & > button {
    margin-top: 1rem;
  }

  @media (max-width: 400px) {
    margin-left: 0;
    margin-top: 1.5rem;
  }
}

.modal {
  display: flex;
  flex-direction: column;

  & > * {
    & + * {
      margin-top: 1rem;
    }
  }
}


.teachers {
  &_wrap {
    margin-top: 3.5rem;
  }

  display: flex;
  flex-direction: column;

  & > div {
    & + div {
      margin-top: 2rem;
    }
  }
}