.root {
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--color-yellow);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 22rem;

  & > svg {
    margin-bottom: 0.8rem;
  }
}

.form {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 1.5rem 2rem 2rem;
  min-width: 25.938rem;
  margin-bottom: auto;

  @media (max-width: 400px) {
    min-width: calc(320px - 2rem);
    width: 100%;
  }
}

.inputs {
  margin-top: 1.5rem;
  
  & > * + * {
    margin-top: 1rem;
  }
}

.buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: 1rem;
  }
}
