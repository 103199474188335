@keyframes SpinnerLoader {
    0% { 
        transform: rotate(0deg); 
    }
    100% {
        transform: rotate(360deg); 
    }
}

.wrap {
    width: 50px;
    height: 50px;
    display: block;
    overflow: hidden;
    background: none;
    margin: auto;
}

.spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.44);
    backface-visibility: hidden;
    transform-origin: 0 0;

    div {
        box-sizing: border-box;
    }

    & > div {
        position: absolute;
        width: 112px;
        height: 112px;
        top: 1px;
        left: 1px;
        border-radius: 50%;
        border: 8px solid #000;
        border-color: var(--color-yellow) var(--color-yellow) var(--color-yellow) #EEEEEE;
        animation: SpinnerLoader 1s linear infinite;

        div {
            &:after,
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 8px;
                height: 8px;
                top: -8px;
                left: 44px;
                background: var(--color-yellow);
                border-radius: 50%;
                box-shadow: 0 104px 0 0 var(--color-yellow);
            }

            &:after {
                left: -8px;
                top: 44px;
                box-shadow: 104px 0 0 0 var(--color-yellow);
            }
        }

        & > div {
            position: absolute;
            width: 100%;
            height: 100%;
            transform: rotate(45deg);
        }
    }
}
