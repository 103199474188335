.sidebar {
  display: flex;
  flex-direction: column;
}

.filter {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  div + div {
    margin-top: 2rem;
  }
}

.label {
  margin-bottom: 1rem;
}

.form {
  & > div {
    & + div {
      margin-top: 1.5rem;
    }
  }

  & > button {
    display: block;
    width: 100%;
    margin-top: 2.5rem;
  }
}

.wrap {
  & > div {
    & + div {
      margin-top: 1rem;
    }
  }
}