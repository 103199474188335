
$primary-text-color: var(--color-primary);
$box-shadow-color: #ffed4e;
$primary: var(--color-yellow);
$primary-hover: #ffd35b;
$active: #ffcc41;

$text-color: var(--color-yellow);

$outline-border: var(--color-yellow);
$outline-border-hover: var(--color-yellow);

$link-hover: #faf7e0;


.button {
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: $primary-text-color;
    padding: 0;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;

    & > span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        text-align: center;

        &:focus {
            outline: none;
        }
    }

    &:focus {
        outline: none;

        & > span {
            position: relative;

            &:after {
                position: absolute;
                content: '';
                box-shadow: 0 0 0 2px $box-shadow-color;
                left: -1px;
                right: -1px;
                top: -1px;
                bottom: -1px;
                border-radius: 4px;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &_fullWidth {
      width: 100%;
    }
}

.extraLarge {
    & > span {
        min-height: 3.56rem;
        padding-left: 2.2222rem;
        padding-right: 2.2222rem;
    }
}

.large {
    & > span {
        min-height: 3.375rem;
        padding-left: 1.3333rem;
        padding-right: 1.3333rem;
    }
}

.medium {
    & > span {
        min-height: 2.75rem;
        padding-left: 1.3333rem;
        padding-right: 1.3333rem;
    }
}

.small {
    & > span {
        min-height: 2.23rem;
        padding-left: 0.89rem;
        padding-right: 0.89rem;
    }
}

.uppercase {
    & > span {
        text-transform: uppercase;
    }
}

.primary {
    background-color: $primary;

    &:hover {
        background-color: $primary-hover;
    }

    &:active {
        background-color: $active;
    }
}

.outline {
    border: 1px solid $outline-border;
    background-color: transparent;

    &:hover {
        border: 1px solid $outline-border-hover;
    }

    &:active {
        border: 1px solid $active;
    }
}

.link {
    background-color: transparent;
    color: var(--color-link);

    & > span {
        padding: 0;
        height: auto;
        font-size: 0.8rem;
        font-weight: normal;
        line-height: normal;
    }
}

.ghost {
    background-color: transparent;
    color: $text-color;
}

.danger {
    background-color: transparent;
    color: var(--color-error);

    & > span {
        padding: 0;
        height: auto;
        font-size: 0.8rem;
        font-weight: normal;
        line-height: normal;
    }
}

.error {
    background-color: var(--color-error);
    color: #FFFFFF;
    border: 1px solid var(--color-error);

    &:hover {
        background-color: var(--color-error);
        border: 1px solid var(--color-error);
    }
}


.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.camera {
    cursor: pointer;
    outline: none;
}