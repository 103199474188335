.root {
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  text-align: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h2 {
    margin-bottom: 2rem;
  }

  & > button {
    margin-top: 5rem;
  }
}