.root {
  width: 100%;
  display: flex;
}

.content {
  flex: 1;
  max-width: 66.6666%;
}

.sidebar {
  flex: 0 1 33.3333%;
  min-width: 33.3333%;
  margin-left: 2rem;
}

@media (max-width: 767px) {
  .root {
    flex-direction: column;
  }

  .sidebar, .content {
    max-width: 100%;
    width: 100%;
    flex: 1;
    margin-left: 0;
  }

  .sidebar {
    margin-top: 2rem;
  }
}